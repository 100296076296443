<template>
  <!-- <b-card-code title="Repeating Forms"> -->
  <!-- select 2 demo -->
  <b-modal
    id="entityDetailsModalId"
    :title="title"
    ok-only
    ok-title="Close"
    cancel-variant="outline-secondary"
  >
    <b-row>
      <!-- Entity Type -->
      <b-col cols="6">
        <b-form-group :label="$t('Entity Type')" label-for="item-type">
          <span class="text-uppercase">{{ entity ? entity.type : "" }}</span>
        </b-form-group>
      </b-col>

      <!-- Entity Name -->
      <b-col cols="6">
        <b-form-group :label="$t('Full Name')" label-for="item-name">
          <span class="text-uppercase">{{
            entity ? entity.fullname : ""
          }}</span>
        </b-form-group>
      </b-col>

      <!-- Entity address -->
      <b-col cols="12">
        <b-form-group :label="$t('Entity Address')" label-for="item-address">
          <span class="text-uppercase">{{ entity ? entity.address : "" }}</span>
        </b-form-group>
      </b-col>

      <!-- Entity Id type -->
      <b-col cols="6">
        <b-form-group :label="$t('Entity ID Type')" label-for="item-id-type">
          <span class="text-uppercase">{{
            entity ? entity.entityIdType : ""
          }}</span>
          <!-- <b-form-select
            id="item-id-type"
            v-model="entity.entityIdType"
            :options="$t('entityIdTypeList')"
            size=""
            class=""
          /> -->
        </b-form-group>
      </b-col>

      <!-- Entity Id Value -->
      <b-col cols="6">
        <b-form-group :label="$t('Entity ID Value')" label-for="item-id-value">
          <span class="text-uppercase">{{
            entity ? entity.entityId : ""
          }}</span>
          <!-- <b-form-input
            id="item-id-value"
            v-model="entity.entityId"
            type="text"
            placeholder="1242523"
          /> -->
        </b-form-group>
      </b-col>

      <!-- Entity phone -->
      <b-col cols="6">
        <b-form-group :label="$t('Phone')" label-for="item-phone">
          <span class="h6">{{ entity ? entity.phone : "" }}</span>
          <!-- <b-form-input
            id="item-phone"
            v-model="entity.phone"
            type="text"
            placeholder="+61 2 9000 8000"
          /> -->
        </b-form-group>
      </b-col>

      <!-- Entity email -->
      <b-col cols="6">
        <b-form-group :label="$t('Email')" label-for="item-email">
          <span class="h6">{{ entity ? entity.email : "" }}</span>
          <!-- <b-form-input
            id="item-email"
            v-model="entity.email"
            type="email"
            placeholder="john@smith.com"
          /> -->
        </b-form-group>
      </b-col>
      <!-- Remove Button -->
      <!-- <b-col lg="2" md="3" class="mb-50 mt-10">
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-danger"
          class="mt-0 mt-md-2"
          @click="removeEntity()"
        >
          <feather-icon icon="XIcon" class="mr-25" />
          <span>Delete</span>
        </b-button>
      </b-col> -->
    </b-row>
  </b-modal>
</template>

<script>
import BCardCode from "@core/components/b-card-code";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BModal,
  VBModal,
} from "bootstrap-vue";
// import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from "vue-ripple-directive";
import { cloneStruct } from "@/@core/utils/utils";
import entity from "@/libs/struct/entity";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import laravel from "@/libs/laravel";

export default {
  components: {
    BFormSelect,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BModal,
    VBModal,
  },
  directives: {
    Ripple,
  },
  // mixins: [heightTransition],
  props: {
    entity: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  created() {},
  destroyed() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
