<template>
  <div>
    <b-row>
      <b-col cols="6" class="mb-2">
        <h5 class="mb-0 h3 text-primary">
          <span v-if="title">{{ title }}</span>
        </h5>
      </b-col>

      <b-col cols="6" class="d-flex justify-content-end align-items-start">
        <b-badge
          :variant="getNftStatusVariant(nft && nft.status ? nft.status : '')"
        >
          {{ $t("nftStatusText")[nft ? nft.status : ""] }}
        </b-badge>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('total_nft_available_for_allocation')"
          label-for="est-value-all-nft-supply"
        >
          <span id="est-value-all-nft-supply" class="h5 text-uppercase">
            {{ formatMoney(nft && nft.supply ? nft.supply : 0) }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('NFT Contract Type')"
          label-for="est-value-all-nft-contract_type"
        >
          <span id="est-value-all-nft-contract_type" class="h5 text-uppercase">
            {{ nft && nft.type }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group
          :label="$t('Estimated Total Value')"
          label-for="est-value-all-nft"
        >
          <span id="est-value-all-nft" class="h5 text-uppercase">
            {{ $t("currencySign") }}
            {{ formatMoney(estTotalValue) }}
          </span>
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="$t('Lease Amount')" label-for="v-lease-amount">
          <span class="h5">
            {{ $t("currencySign") }}
            {{ amount }}
            /
            {{ freq }}
          </span>
        </b-form-group>
      </b-col>
      <b-col cols="12" class="mb-2">
        <b-list-group>
          <b-list-group-item
            v-for="hold in holdings"
            :key="hold.id"
            ref="row"
            class="flex-column align-items-start"
            v-show="hold.holding > 0"
          >
            <div class="row d-flex justify-content-between">
              <b-form-group
                class="px-1"
                :label="$t('holding') + '(ID:' + hold.id + ')'"
                :label-for="'select_nft_holding_ent_' + hold.id"
              >
                <span>
                  {{ formatMoney(hold.holding) }}
                  ( {{ allocationPercent(hold.holding) }}% )
                </span>
              </b-form-group>

              <b-form-group
                class="px-1"
                :label="$t('Est Value of Alloation')"
                label-for="est-value-per-nft-value-alloc"
              >
                <span
                  id="est-value-per-nft-value-alloc"
                  class="h5 text-uppercase"
                >
                  {{ $t("currencySign") }}
                  {{ formatMoney(showValuePerAlloc(hold.holding)) }}
                </span>
              </b-form-group>

              <b-form-group
                class="px-1"
                :label="$t('Est Allocation Income') + ' / ' + freq"
                label-for="est-value-per-nft"
              >
                <span id="est-value-per-nft" class="h5 text-uppercase">
                  {{ $t("currencySign") }}
                  {{ formatMoney(showAllocIncomePerFreq(hold.holding)) }}
                </span>
              </b-form-group>
            </div>
          </b-list-group-item>
        </b-list-group>
      </b-col>

      <show-unsigned-entities
        v-if="nft.status == $t('nftStatus.DRAFT')"
        :title="$t('Unsigned Entities')"
        :entities="lease ? lease.entities : []"
        :nft="nft ? nft : null"
        :lease="lease ? lease : null"
      />
    </b-row>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BCard,
  BListGroup,
  BListGroupItem,
  BButtonGroup,
  BBadge,
} from "bootstrap-vue";

import { getNameFromFileObj, timeToGo, showInfoModal } from "@/libs/helpers";

import FormEntitiesSummary from "../form-entities/FormEntitiesSummary";
import FormLeaseTermsSummary from "@/views/forms/components/form-leases/FormLeaseTermsSummary";
import ShowUnsignedEntities from "@/views/card/app-custom/show-unsigned-entities.vue";

import {
  estLeaseValue,
  dollarUSLocale,
  estValuePerNft,
  estIncomePerNftPerFreq,
  leaseDaysLeft,
} from "@/libs/financeHelper";

import Cleave from "vue-cleave-component";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BFormTextarea,
    BCardText,
    BListGroup,
    BListGroupItem,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,
    BButtonGroup,
    BBadge,

    Cleave,

    FormEntitiesSummary,
    FormLeaseTermsSummary,
    ShowUnsignedEntities,
  },
  props: {
    holdings: {
      type: Array,
      default: [],
    },
    nft: {
      type: Object,
      default: null,
    },
    lease: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
  },
  watch: {
    holdings(oldHoldings, newHoldings) {
      if (
        !this.lease ||
        !this.lease.entities ||
        this.lease.entities.length <= 0
      ) {
        return;
      }
      let nftHoldings = {};
      for (let i = 0; i < newHoldings.length; i++) {
        let hold = newHoldings[i];
        nftHoldings[hold.entity_id] = hold;
      }

      // reset allocations
      this.allocations = [];
      for (let i = 0; i < this.lease.entities.length; i++) {
        let entity = this.lease.entities[i];
        entity.hold = nftHoldings[entity.id] ? nftHoldings[entity.id] : null;
        this.allocations.push(entity);
      }
    },
  },
  data() {
    return {
      allocations: [],
    };
  },
  mounted() {},
  computed: {
    supply() {
      return this.nft && this.nft.supply ? this.nft.supply : 0;
    },
    entities() {
      return this.lease ? this.lease.entities : [];
    },
    showNftIncomePerFreq() {
      return estIncomePerNftPerFreq(
        this.showValuePerNft,
        this.daysLeft,
        this.freq
      );
    },
    freq() {
      return this.lease && this.lease.paym_freq ? this.lease.paym_freq : "";
    },
    amount() {
      return this.lease && this.lease.paym_freq_amt
        ? this.formatMoney(this.lease.paym_freq_amt)
        : 0;
    },
    daysLeft() {
      if (!this.lease || !this.lease.start_date) return 0;
      return leaseDaysLeft(this.lease);
    },
    leaseTimeLeft() {
      if (this.lease && this.lease.start_date && this.lease.end_date) {
        return timeToGo(this.lease.start_date, this.lease.end_date);
      } else {
        return "";
      }
    },
    estTotalValue() {
      if (this.lease) return estLeaseValue(this.lease);
      else return 0;
    },
    showValuePerNft() {
      // console.log("supply : ", this.nft.supply);
      return estValuePerNft(this.estTotalValue, this.supply);
    },
  },
  methods: {
    allocationPercent(allocation) {
      return Math.round((allocation * 10000) / this.supply) / 100;
    },
    showValuePerAlloc(allocation) {
      return allocation * estValuePerNft(this.estTotalValue, this.supply);
    },
    showAllocIncomePerFreq(allocation) {
      return (
        allocation *
        estIncomePerNftPerFreq(this.showValuePerNft, this.daysLeft, this.freq)
      );
    },
    // assignAllocation(entity, option) {
    //   // console.log("assign allocation: ", entity, option);
    //   entity.allocation = this.supply * option.percent;
    //   // this.$emit("input");
    //   let elem = document.getElementById(
    //     "select_nft_allocation_ent_" + entity.name
    //   );
    //   // elem.value = entity.allication;
    //   // console.log("dispatch", elem);
    //   elem.dispatchEvent(new Event("input"));
    //   // this.$set(entity, "allocation", this.supply * option.percent);
    // },
    formatMoney(amt) {
      return dollarUSLocale.format(amt);
    },
    getNameFromFileObj: function (fname) {
      return getNameFromFileObj(fname);
    },

    removeTimeFromDate: function (timeStr) {
      let dd = new Date(Date.parse(timeStr));
      return dd.getFullYear() + "-" + dd.getMonth() + "-" + dd.getDate();
    },
    getNftStatusVariant(status) {
      switch (status) {
        case "draft":
          return "warning";
          break;
        case "minted":
          return "primary";
          break;
        case "ready2mint":
          // code block
          return "info";
          break;
        case "active":
          return "success";
          break;
        case "complete":
          // code block
          return "dark";
          break;
        default:
          // code block
          return "info";
      }
    },
  },
};
</script>
