<template>
  <div>
    <b-row>
      <entities-list-short
        :entities="entitiesArray"
        :title="title ? title : $t('NftContractSigners.title')"
        :subtitle="subtitle ? subtitle : $t('NftContractSigners.subtitle')"
      />
    </b-row>
  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Ripple from "vue-ripple-directive";
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BCardText,
  BCard,
  BListGroup,
  BListGroupItem,
} from "bootstrap-vue";

import { getNameFromFileObj } from "@/libs/helpers";

import FormEntitiesSummary from "../form-entities/FormEntitiesSummary";
import FormLeaseTermsSummary from "@/views/forms/components/form-leases/FormLeaseTermsSummary";

import EntitiesListShort from "@/views/forms/components/form-entities/EntitiesListShort.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    BFormTextarea,
    BCardText,
    BListGroup,
    BListGroupItem,

    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCardText,

    FormEntitiesSummary,
    FormLeaseTermsSummary,
    EntitiesListShort,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    lease: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    entitiesArray() {
      return this.lease ? this.lease.entities : [];
    },
  },
  methods: {
    formatMoney(amt) {
      return dollarUSLocale.format(amt);
    },
    getNameFromFileObj: function (fname) {
      return getNameFromFileObj(fname);
    },
  },
};
</script>
