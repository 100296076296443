<template>
  <b-card>
    <form-nft-summary
      :holdings="nft ? nft.holdings : []"
      :lease="lease"
      :nft="nft"
      :title="$t('NFT Details')"
    />

    <form-lease-summary
      :show_card="false"
      :lease="lease"
      :title="'Lease Details'"
    />
  </b-card>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";

import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import entity from "@/libs/struct/entity";
import address from "@/libs/struct/address";
import { cloneStruct } from "@core/utils/utils";
import { saveToLocal, getLocal, updateArrElemById } from "@/libs/helpers";

import FormLeaseSummary from "@/views/forms/components/form-leases/FormLeaseSummary.vue";
import FormNftSummary from "@/views/forms/components/form-mint/FormNftSummary.vue";

import MintEntitiesSummary from "@/views/forms/components/form-mint/MintEntitiesSummary.vue";

import Ripple from "vue-ripple-directive";

import laravel from "@/libs/laravel";

import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormDatepicker,
  BCardText,
  BCard,
  BCardTitle,
  BCardFooter,
  BCardBody,
} from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { sync } from "vuex-pathify";
import { getArrElemById, sortEntitiesArrayByType } from "@/libs/helpers";

export default {
  directives: {
    Ripple,
  },
  components: {
    MintEntitiesSummary,
    FormLeaseSummary,
    FormNftSummary,
    BFormSelect,
    BFormTextarea,
    BCardText,
    BCard,
    BCardTitle,
    BCardFooter,
    BCardBody,
    BFormDatepicker,

    FormWizard,
    TabContent,

    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      leaseId: null,
      lease: null,
      nftId: null,
    };
  },
  computed: {
    // user: sync("user/profile"),
    nfts: sync("nfts/list"),
    portfolio: sync("nfts/portfolio"),
    nft() {
      if (!this.lease) {
        return null;
      }

      this.nftId = parseInt(this.$route.params.nftId);
      let finalNft = null;
      console.log("find nft id " + this.nftId);
      console.log("find nft id from portfolio: ", this.portfolio);

      for (let i = 0; i < this.nfts.length; i++) {
        let nft = this.nfts[i];
        if (nft.id == this.nftId) {
          finalNft = nft;
        }
      }

      // for (let i = 0; i < this.lease.nfts.length; i++) {
      //   let nft = this.lease.nfts[i];
      //   if (nft.id == this.nftId) {
      //     // find the nft obj from portfolio which inclues the actual
      //     // holdings array
      // for (let n = 0; n < this.portfolio.length; n++) {
      //   if (this.nftId == this.portfolio[n].id) {
      //     finalNft = this.portfolio[n];
      //   }
      // }

      console.log("get nft details found: ", finalNft);
      return finalNft;
      // }
      // }
    },
  },
  mounted() {
    this.leaseId = parseInt(this.$route.params.leaseId);
    this.nftId = parseInt(this.$route.params.nftId);

    console.log("show lease details leaeid:", this.leaseId);
    // edit mode
    if (this.leaseId > 0) {
      laravel.leases
        .show(this.leaseId)
        .then((resp) => {
          console.log("found edit lease resp: ", resp);
          let leaseObj = JSON.parse(JSON.stringify(resp.data));
          let sortedArrs = sortEntitiesArrayByType(leaseObj.entities);
          leaseObj.leasors = sortedArrs.leasors;
          leaseObj.leasees = sortedArrs.leasees;
          leaseObj.guarantors = sortedArrs.guarantors;
          leaseObj.documents = leaseObj.files;
          this.lease = leaseObj;

          // this.getNftDetails(this.lease, this.nftId);
        })
        .catch((error) => {
          console.log("Error: ", error.message);
          // route back toast then route back

          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error editing lease.",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          // setTimeout(() => this.$router.go(-1), 1000);
        });
    }
  },
  methods: {},
};
</script>
