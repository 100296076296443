<template>
  <!-- <b-card-code title="Repeating Forms"> -->
  <b-col cols="12">
    <entity-details-modal
      :entity="showModalEntity"
      :subtitle="$t('update entity subtitle')"
      :title="$t('update entity')"
    />

    <div class="mt-1">
      <h4 class="mb-1 h4 text-primary">
        {{ title }}
      </h4>
      <h5 class="mb-2 h6 text-secondary">
        {{ subtitle }}
      </h5>
    </div>

    <div class="mb-2">
      <b-list-group>
        <b-list-group-item
          v-for="(entity, index) in entities"
          :id="entity.id"
          :key="index"
          ref="row"
          class="flex-column align-items-start"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1 text-primary text-uppercase">
              {{ $t(entity.act_as) }}
            </h5>
            <small class="text-secondary h6">
              {{ entity.fullname }}
              <a @click="showEntity(entity)"
                ><feather-icon class="text-primary h5" icon="EyeIcon"
              /></a>
            </small>
          </div>
          <b-card-text class="mb-0">
            <div class="d-flex justify-content-between align-items-center">
              <div class="text-uppercase">{{ $t(entity.type) }}</div>
              <div class="text-secondary text-sm">{{ entity.email }}</div>
            </div>
            <!-- <div class="d-flex justify-content-between">
              <small class="text-secondary text-uppercase">{{
                entity.address
              }}</small>

              <small class="text-secondary">{{ entity.phone }}</small>
            </div> -->
          </b-card-text>
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-col>
</template>

<script>
import BCardCode from "@core/components/b-card-code";

import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  BListGroup,
  BListGroupItem,
  BCardText,
  BBadge,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { cloneStruct } from "@/@core/utils/utils";
import entity from "@/libs/struct/entity";
// import FormEntityModal from "@/views/forms/components/form-entities/FormEntityModal.vue";
import EntityDetailsModal from "@/views/card/app-custom/EntityDetailsModal.vue";

export default {
  components: {
    BFormSelect,
    BCardCode,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BCardText,
    BBadge,
    // FormEntityModal,
    EntityDetailsModal,
  },
  directives: {
    Ripple,
  },
  // mixins: [heightTransition],
  props: {
    entities: {
      type: Array,
      default: [],
    },
    title: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      editModalEntity: null,
      showModalEntity: null,
    };
  },
  mounted() {
    // this.initTrHeight();
    // console.log("show summary: ", this.entities);
  },
  created() {},
  destroyed() {},
  methods: {
    showEntity(entity) {
      // console.log("show entity: ", entity);
      this.showModalEntity = entity;
      this.$bvModal.show("entityDetailsModalId");
    },
    editEntity(entity) {
      // console.log("edit entity: ", entity);
      this.editModalEntity = entity;
      this.$bvModal.show("editEntityModalId");
    },
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
